<template>
  <div class="register">
    <div class="input">
      <p class="input-name">手机号</p>
      <div class="input-border">
        <input placeholder="手机号" type="text" v-model="mobile" maxlength="11" />
      </div>
    </div>
    <div class="input">
      <p class="input-name">短信验证码</p>
      <div class="input-border">
        <input class="code" placeholder="短信验证码" type="text" v-model="code"/>
        <div class="getCode" v-show="!hasSend" @click="sendCode">获取验证码</div>
        <div class="getCode" v-show="hasSend">重新获取{{count}}S</div>
      </div>
    </div>
    <div class="input">
      <p class="input-name">新密码</p>
      <div class="input-border">
        <input placeholder="新密码" type="password" v-model="newPassword"/>
      </div>
    </div>

    <div class="button">
      <div class="btn" @click="reset">重置密码</div>
    </div>
  </div>
</template>

<script>
  export default {
    beforeMount() {
      document.title = '明九章-忘记密码';
    },
    mounted(){

    },
    data () {
      return {
        mobile: '',
        newPassword: '',
        code: '',
        count: 60,
        hasSend: false,
      }
    },
    methods: {
      reset() {
        if (this.mobile.length != 11) {
          alert('请输入正确的手机号');
          return;
        }
        if (this.code === '') {
          alert('短信验证码不能为空');
          return;
        }
        if (this.newPassword === '') {
          alert('密码不能为空');
          return;
        }
        if (this.newPassword.length < 6) {
          alert('密码长度要大于等于6位');
          return;
        }
        this.$http.put('api/user/password', {
            mobile: this.mobile,
            code: this.code,
            newPassword: this.newPassword
        })
        .then((res) => {
            localStorage.removeItem('ng-data');
            localStorage.removeItem('ng-token');
            setTimeout(() => {
              this.$router.push('/login');
            })
        }).catch((err) => {

        })
      },
      sendCode() {
        if (this.mobile.length != 11) {
          alert('请输入正确的手机号');
          return;
        }
        if (this.hasSend) {
          return;
        }
        this.hasSend = true;
        this.$http.post('/anonymous/sms/sendCode', {
            mobile: this.mobile,
            msgType: 'password', // 修改密码
            userType: 'api', // C端用户
        })
        .then((res) => {
          this.inter = setInterval(() => {
            this.count --;
            if (this.count < 0) {
              this.count = 60;
              this.hasSend = false;
              window.clearInterval(this.inter);
            }
          }, 1000);
        }).catch((err) => {
            this.hasSend = false;
        })
      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
  .register {
    padding-top: 0.2rem;
    .input {
      padding: 0 0.3rem;
      color: #79cdca;
      font-size: 0.3rem;
      margin-bottom: 0.5rem;
      .input-name {
        margin-bottom: 0.12rem;
        font-size: 0.26rem;
      }
      .input-border {
        border: 1px solid #79cdca;
        height: 0.9rem;
        border-radius: 0.12rem;
        display: flex;
        align-items: center;
        position: relative;
        input {
          // &.code {
          //   width: 4rem;
          // }
          color: #333333;
          height: 0.75rem;
          width: 92%;
          margin: 0 auto;
          display: block;
          font-size: 0.3rem;
        }
      }
      .getCode {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 0.2rem;
        background-color: #79cdca;
        height: 0.5rem;
        line-height: 0.5rem;
        text-align: center;
        width: 1.6rem;
        border-radius: 0.12rem;
        font-size: 0.26rem;
        color: white;
        z-index: 10;
        cursor: pointer;
      }
    }
    .button {
      padding: 0 0.3rem;
      box-sizing: border-box;
      margin: 0.5rem auto 0;
      border-radius: 0.12rem;
      color: white;
      overflow: hidden;
      .btn {
        background-color: #79cdca;
        width: 100%;
        height: 0.9rem;
        border-radius: 0.12rem;
        text-align: center;
        line-height: 0.9rem;
        font-size: 0.32rem;
        cursor: pointer;
      }
    }
  }
</style>
